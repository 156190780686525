<template>
  <img alt="Vue logo" src="./assets/logo.png">
  
   <HelloWorld :msg=msg  @delete="deleteCounter"/>

      <nav>
    <RouterLink to="/">Go to Home</RouterLink>
    <RouterLink to="/about">Go to About</RouterLink>
  </nav>

   <RouterView />
</template>

<script>

import HelloWorld from './components/HelloWorld.vue'
// import testApi from './components/testApi.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
   // testApi
  },
  data(){
    return{
     msg : "welcome",
  }
  },
  methods:{
deleteCounter(){
     this.msg = "تم الحذف";
}
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
