<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div v-for="item in users" :key="item.id">
      {{ item.name }} {{item.age}}

      <button @click="count++">Add 1</button>
<p>Count is: {{ count }}</p>
 <button v-on:click="deleteCounter()">delete counter</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return{
    count : 0,
      users:[
        { id : '1', name: 'ruba' , age: '25'}
      ]
    }
  },
  methods: {
    deleteCounter(){
      this.count = 0;
      this.$emit('delete')
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
