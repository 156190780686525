// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import About from '../views/About.vue'



// Vue.use(VueRouter)

//   const routes = [
//   {
//     path: '/home',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     component: About
//   },

// //   {
// //     path: '/profile/:userid',
// //     name: 'Profile',
// //     component: Profile
// //   } 
// ]

// const router = new VueRouter({
//    mode: 'history',
//   routes
// })

import { createMemoryHistory, createRouter } from 'vue-router'

import Home from '../views/Home.vue'
import About from '../views/About.vue'

const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
]

const router = createRouter({
  history: createMemoryHistory(),
  routes,
})


export default router